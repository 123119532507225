export const publications = [
  {
    id: 5,
    title:
      "User perspective and higher cognitive task-loads influence movement and performance in immersive training environments",
    year: "2019",
    month: "May",
    authors: [
      "J. Trelles Trabucco",
      "A. Rottigni",
      "M. Cavallo",
      "D. Bailey",
      "J. Patton",
      "G.E. Marai",
    ],
    url: "https://link.springer.com/article/10.1186/s42490-019-0021-0",
    venue: "BMC Biomedical Engineering",
    award: null,
    teaser: "https://jtrell2.people.uic.edu/assets/images/rehabjim-teaser.png",
    demo: null,
    shortVenue: "BMC"
  },
  {
    id: 4,
    title: "Visual Analysis of a Smart City’s Energy Consumption",
    year: "2019",
    month: "May",
    authors: ["J. Trelles Trabucco", "D. Lee", "S. Derrible", "G.E. Marai"],
    url: "https://www.mdpi.com/2414-4088/3/2/30",
    venue:
      "Special Issue Interactive Visualizations for Sustainability, Multimodal Technologies and Interaction",
    award: null,
    teaser: "https://jtrell2.people.uic.edu/assets/images/chicago-energy.png",
    demo: "http://chicagoenergy.evl.uic.edu:3000/",
    shortVenue: "MDPI"
  },
  {
    id: 3,
    title: "Multi-scale Voronoi-based ACT Assessment",
    year: "2016",
    month: "October",
    authors: [
      "T. Luciani",
      "J. Trelles Trabucco",
      "C. Ma",
      "A. Burks",
      "M. Thomas",
      "K. Bharadwaj",
      "S. Singh",
      "P. Hanula",
      "L. Di",
      "G.E. Marai",
    ],
    url: "http://vacommunity.org/ieeevpg/viscontest/2016/entries/3.html",
    venue:
      "IEEE VGTC VPG International Data-Visualization Contest, Baltimore, MD, USA",
    award: "Honorable Mention",
    teaser: "https://jtrell2.people.uic.edu/assets/images/act.png",
    demo: null,
    shortVenue: "IEEE VGTC"
  },
  {
    id: 2,
    title:
      "Developing a Data-Driven Wiki of Spatial-Nonspatial Integration Tools",
    year: "2016",
    month: "October",
    authors: ["T. Luciani", "C. Ma", "J. Trelles Trabucco", "G.E. Marai"],
    url:
      "https://jtrell2.people.uic.edu/assets/papers/Abstract_Luciani_C4PGV.pdf",
    venue:
      "In Proceedings of the IEEE VIS 2016 Workshop C4PGV, Baltimore, MD, USA",
    award: null,
    teaser: "https://jtrell2.people.uic.edu/assets/images/wiki.png",
    demo: null,
    shortVenue: "IEEE VIS Workshop"
  },
  {
    id: 1,
    title:
      "Spatial Analysis of Employee Safety Using Organizable Event Quiltmaps",
    year: "2016",
    month: "October",
    authors: [
      "D. McNamara",
      "J. Tapia",
      "T. Luciani",
      "A. Burks",
      "J. Trelles Trabucco",
      "G.E. Marai",
    ],
    url: "https://eventevent.github.io/papers/EVENT_2016_paper_22.pdf",
    venue:
      "In Proceedings of the IEEE VIS 2016 Workshop on Temporal and Sequential Event Analysis, Baltimore, MD, USA",
    award: null,
    teaser: "https://jtrell2.people.uic.edu/assets/images/quiltmap-teaser.png",
    demo: null,
    shortVenue: "IEEE VIS Workshop"
  },
];

export const projects = [
  {
    id: 9,
    title: "I-Walk-o-K",
    description:
      "Inverse kinematics for animating tentacles and user-controlled legs. Solver uses a pseudo-inverse Jacobian method.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/ik.gif",
    web: "https://jtrell2.people.uic.edu/cs527/final/",
    technologies: ["three.js"],
    class: "Computer Animation (CS527)",
  },
  {
    id: 8,
    title: "Flocking",
    description:
      "Flocking simulation based on the behavior rules described by Craig Raynolds in his 1987 paper. Boids navigate between four control points while avoiding objects in the scene and waiting for their buddies to arrive safe and sound.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/flocking.gif",
    web: "https://jtrell2.people.uic.edu/cs527/assignment3/",
    technologies: ["three.js"],
    class: "Computer Animation (CS527)",
  },
  {
    id: 7,
    title: "Motion Capture and Splines",
    description:
      "Motion capture animation using B-Splines using the Ohio State version of the CMU database.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/mocap.gif",
    web: "https://jtrell2.people.uic.edu/cs527/assignment2/",
    technologies: ["three.js"],
    class: "Computer Animation (CS527)",
  },
  {
    id: 6,
    title: "2D Particle Animation",
    description:
      "Physically-based animation of colliding balls using Euler integration, coefficients of restitution, air friction and mass. Small explosions demonstrate the use of the secondary action principle of animation. ",
    teaser: "https://jtrell2.people.uic.edu/assets/images/euler.gif",
    web: "https://jtrell2.people.uic.edu/cs527/assignment1/",
    technologies: ["javascript", "canvas"],
    class: "Computer Animation (CS527)",
  },
  {
    id: 5,
    title: "Energy Consumption in Chicago",
    description:
      "Visualization of the Chicago's dataset for gas and electricity consumption in 2010. User explores the data via a search or an explore workflow at different levels of aggregation. This work is an older version of the Visual Analysis of a Smart City's Energy Consumption paper listed above.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/electricity.png",
    web: "",
    technologies: ["javascript", "D3"],
    class: "Scientific Visualization (CS526)",
  },
  {
    id: 4,
    title: "Coldre",
    description:
      "Third person CAVE2 game about the sadness produced by a divorce and the sudden disappearance of colors in the world of dreams. Game explores the use of storytelling and third-person controllers in a CAVE2 large-scale virtual reality environment.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/coldre.gif",
    web: "https://www.youtube.com/watch?v=JBwSAxoVguY",
    technologies: ["Unity3D", "CAVE2"],
    class: "Videogames Design and Development (CS426)",
  },
  {
    id: 3,
    title: "Rave On",
    description:
      "Analysis of music genres and musicians using Spotify data. Database lost, demo down.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/music.png",
    web: null,
    technologies: ["javascript", "D3"],
    class: "Data Visualization and Visual Analytics (CS424)",
  },
  {
    id: 2,
    title: "Shadows in the Rain",
    description:
      "Visualization of hurricane paths in the Atlantic and Pacific ocean. Includes an analysis of seasonal variation, trends over time, and affected areas.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/hurricane.jpg",
    web: null,
    technologies: ["javascript", "D3"],
    class: "Data Visualization and Visual Analytics (CS424)",
  },
  {
    id: 1,
    title: "Still crazy after all these years!",
    description:
      "Very first experience with D3, and first designed for an 8K display. Visualization allows you to infer how many people may remember some historical events based on age distributions.",
    teaser: "https://jtrell2.people.uic.edu/assets/images/crazy.png",
    web: "https://jtrell2.people.uic.edu/cs424/still_crazy/",
    technologies: ["javascript", "D3"],
    class: "Data Visualization and Visual Analytics (CS424)",
  },
];
