import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SideInfo from "./SideInfo";
import Summary from "./Summary";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: 100    
  },
  grid: {
    width: 1080,
    margin: 'auto'
  }
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid} justify="center">
        <Grid item sm={2}>
          <SideInfo />
        </Grid>
        <Grid item sm={10}>
          <Summary />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
