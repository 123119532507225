import React, { Component } from "react";
import HomePage from "./home/HomePage";

class App extends Component {
  render() {
    return (
      <>
        <HomePage />
      </>
    );
  }
}

/*
const App = () => (
  <div className="container-fluid">
    <Header />
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/about" component={AboutPage} />
      <Route component={PageNotFound} />
    </Switch>
  </div>
);*/

export default App;
