import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Publication from "./Publication";

const PublicationList = ({ publications }) => (
  <List>
    {publications.map((publication) => {
      return (
        <Publication
          key={publication.id}
          publication={publication}
          myself={"J. Trelles Trabucco"}
        />
      );
    })}
  </List>
);

PublicationList.propTypes = {
  publications: PropTypes.array.isRequired,
};

export default PublicationList;
