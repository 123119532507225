import React from "react";
import { Typography } from "@material-ui/core";
import PublicationList from "./PublicationList";
import ProjectList from "./ProjectsList";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { publications, projects } from "../../store";

const styles = {
  padding: 30,
  marginTop: 10,
  marginBottom: 10,
  height: "85vh",
  overflowY: "scroll",
};

const Summary = () => (
  <Paper style={styles}>
    <Typography variant="body1" align="justify" gutterBottom>
        I&apos;m a Ph.D. candidate in Computer Science at the University of Illinois at 
        Chicago (<Link href="https://cs.uic.edu/" target="_blank" rel="noopener noreferrer">UIC</Link>) under the supervision of <Link href="https://www.evl.uic.edu/marai/" target="_blank" rel="noopener noreferrer">Dr. Liz Marai</Link>. I
        obtained my master&apos;s degree in Computer Science at UIC as a Fulbright
        awardee. I&apos;m currently working as a Research Assistant at
        the Electronic Visualization Laboratory (<Link href="https://www.evl.uic.edu/" target="_blank" rel="noopener noreferrer">EVL</Link>). My research interests are Data Visualization, Computer Graphics, and Machine Learning.      
    </Typography>
    <Typography variant="body1">
      {""}
    </Typography>
    <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
    <Typography variant="h5">Publications</Typography>    
    <PublicationList publications={publications} />
    <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
    <Typography variant="h5">Projects</Typography>
    <ProjectList projects={projects} />
  </Paper>
);

export default Summary;
