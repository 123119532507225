import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const Project = ({ project }) => {
  const handleClickDemo = () => window.open(project.web, "_blank");
  const displayDemoIcon = () => {
    if (project.web !== null)
      return (<IconButton color="primary" size="small"><WebOutlinedIcon fontSize="small" onClick={handleClickDemo} /></IconButton>);
  };
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <img
          src={project.teaser}
          width="120px"
          height="90px"
          style={{ marginLeft: "8px", marginRight: "8px" }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography color="textPrimary">{project.title}</Typography>
            <Typography color="textSecondary">{project.description}</Typography>
          </>
        }
        secondary={displayDemoIcon()}
      />
    </ListItem>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Project;
