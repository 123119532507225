import React from "react";
import Paper from "@material-ui/core/Paper";
import { Avatar, Typography } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";
import IconButton from "@material-ui/core/IconButton";

const avatarStyles = {
  fontSize: 80,
  height: "auto",
  width: "auto",
};

const styles = {
  padding: 20,
  marginTop: 10,
  marginBottom: 10,
  overflow: "hidden",
};


const handleGithub = () => window.open("https://github.com/jtrells", "_blank");
const handleLinkedIn = () =>
  window.open("https://www.linkedin.com/in/juan-trelles-trabucco/", "_blank");
const handleTwitter = () =>
  window.open("https://twitter.com/jtrells", "_blank");

const SideInfo = () => (
  <>
    <Paper style={styles} elevation={0}>
      <Avatar
        style={avatarStyles}
        alt="Juan Trelles"
        src={"https://jtrell2.people.uic.edu/assets/images/juan.jpg"}
        variant="rounded"
      />
      <Typography align="center" variant="h6">
        Juan Trelles
      </Typography>
      <Typography align="center" variant="subtitle2" color="textSecondary">
        Ph.D Candidate
      </Typography>
      <Typography align="center" component="p" variant="caption" color="textPrimary">
        jtrell2 at uic.edu
      </Typography>
      <div align="center">
      <IconButton color="primary" size="small">
        <LinkedInIcon onClick={handleLinkedIn} />
      </IconButton>
      <IconButton color="primary" size="small">
        <GitHubIcon onClick={handleGithub} />
      </IconButton>
      <IconButton color="primary" size="small">
        <TwitterIcon onClick={handleTwitter} />
      </IconButton>      
      </div>      
    </Paper>
  </>
);

export default SideInfo;
