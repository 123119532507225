import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import IconButton from "@material-ui/core/IconButton";

function parseAuthors(authors) {
  let parsedAuthors = authors.map((author) => [author, ", "]).flat();
  const len = parsedAuthors.length;

  parsedAuthors[len - 1] = parsedAuthors[len - 2];
  parsedAuthors[len - 2] = "and ";
  return parsedAuthors;
}

const Publication = ({ publication, myself }) => {
  let authors = parseAuthors(publication.authors);

  // Icon helper functions
  const handleClickPublication = () => window.open(publication.url, "_blank");
  const handleClickDemo = () => window.open(publication.demo, "_blank");
  const displayDemoIcon = () => {
    if (publication.demo !== null)
      return (<IconButton color="primary" size="small"><WebOutlinedIcon fontSize="small" onClick={handleClickDemo}/></IconButton> );
  };
  const displayPublicationIcon = () => {
    if (publication.web !== null)
      return (
        <IconButton color="primary" size="small">
          <DescriptionOutlinedIcon
            fontSize="small"
            onClick={handleClickPublication}
          />
        </IconButton>
      );
  };

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <img
          src={publication.teaser}
          width="120px"
          height="90px"
          style={{ marginLeft: "8px", marginRight: "8px" }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="body2" color="textPrimary"><strong>{publication.title}</strong></Typography>

            {authors.map((author, i) =>
              author === myself ? (
                <Typography component="span" variant="caption" key={i} color="textPrimary">
                  {author}
                </Typography>
              ) : (
                <Typography component="span" variant="caption" key={i} color="textSecondary">
                  {author}
                </Typography>
              )
            )}
            <Typography variant="caption" color="textSecondary">{`, ${publication.shortVenue} (${publication.year})`}</Typography>
          </>
        }
        secondary={
          <>
            {displayPublicationIcon()}
            {displayDemoIcon()}
          </>
        }
      />
    </ListItem>
  );
};

Publication.propTypes = {
  publication: PropTypes.object.isRequired,
  myself: PropTypes.string.isRequired,
};

export default Publication;
