import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Project from "./Project";

const ProjectsList = ({ projects }) => (
  <List>
    {projects.map((project) => {
      return <Project key={project.id} project={project} />;
    })}
  </List>
);

ProjectsList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectsList;
